<template>
  <div>
    <v-card>
      <v-tabs v-model="tab" fixed-tabs background-color="green" dark>
        <v-tabs-slider color="#086318"></v-tabs-slider>
        <v-tab :disabled="newDonor">CADASTRAR DOADOR</v-tab>
        <v-tab >INFORMES CLÍNICOS</v-tab>
        <!-- <v-tab :disabled="disabledReports">SOROLOGIA</v-tab> -->
        <v-tab :disabled="disabledAttachments" @disableTab="disabledAttachments = $event">ANEXOS</v-tab>

        <!-- TAB 1 -->
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <!-- alterei o ref -->
              <v-form @submit.prevent="createDoador" ref="form">
                <v-container>
                  <v-row>
                    <!-- <v-col class="d-flex" cols="12" sm="4">
                      <v-radio-group v-model="donorForm.kind_of_donor_id" :disabled="this.isME">
                        <p>Tipo de Doador:</p>

                        <v-radio label="PCR" 
                        value="1" 
                        color="#086318"
                        ></v-radio>

                        <v-radio label="ME " 
                        value="2" 
                        color="#086318"
                        ></v-radio>
                      </v-radio-group>

                    </v-col> -->

                    <v-col class="d-flex" cols="12" sm="4">
                      <v-radio-group v-model="vue.identified" :disabled="this.isME">
                        <p>Doador Identificado?</p>

                        <v-radio label="Sim" 
                        value="true" 
                        color="#086318"
                        ></v-radio>

                        <v-radio label="Não" 
                        value="false" 
                        color="#086318"
                        ></v-radio>
                      </v-radio-group>

                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4">
                      <!-- <v-radio-group 
                      :disabled="disableDonorStatus"
                      :rules="formRules" 
                      v-model="donorForm.donor_status_id"> essa parte foi removida, solicitado dia (05/02)--> 
                      <v-radio-group 
                      :rules="formRules" 
                      v-model="donorForm.donor_status_id">

                      <p>Doador Vivo?</p>
                        <v-radio 
                        label="Sim"  
                        value=1
                        color="#086318"></v-radio>

                        <v-radio label="Não"  
                        value=2
                        color="#086318"></v-radio>
                      </v-radio-group>

                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="d-flex" cols="12" sm="4" v-if="this.$store.state.profile_id == 1">
                      <!-- Aqui vira um select -->
                      <v-select 
                      :items="hospital"
                      :rules="formRules"
                      color="#086318" 
                      v-model="donorForm.hospital_id"
                      item-value="id"
                      item-text="hospital"  
                      dense 
                      outlined 
                      label="Hospital Notificante">
                      </v-select>
                    </v-col>

                    <!-- reparar isso depois -->

                    <v-col class="d-flex" cols="12" sm="2">
                      <v-text-field 
                      color="#086318" 
                      label="CNES" 
                      placeholder="CNES"
                      item-text="hospitalSelected.cnes"
                      outlined
                      dense
                      v-model="donorForm.cnes" 
                      maxlength="14" 
                      v-mask="'##############'"
                      ></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="2">
                      <v-text-field
                      color="#086318" 
                      label="RGCT" 
                      placeholder="Apenas Números" 
                      outlined
                      maxlength="14" 
                      v-mask="'##############'"
                      v-model="donorForm.rgct" 
                      dense></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <h3 row>Informações Pessoais</h3>
                  </v-row>

                  <v-row>
                    <v-col class="d-flex" cols="12" sm="4" v-if="vue.identified == 'true'">
                      <v-radio-group  
                      v-model="vue.hasCpf"
                      :rules="formRules">

                        <p>Possui CPF?</p>

                        <v-radio label="Sim"
                         value="true" 
                         color="#086318"
                         ></v-radio>

                        <v-radio label="Não"
                         value="false" 
                         color="#086318"
                         ></v-radio>
                      </v-radio-group>

                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4">
                      <v-radio-group 
                      :rules="formRules" 
                      v-model="donorForm.sex_id">

                        <p>Gênero</p>

                        <v-radio 
                        label="Feminino" 
                        value=1 
                        color="#086318"
                        ></v-radio>

                        <v-radio 
                        label="Masculino" 
                        value=2 
                        color="#086318"
                        ></v-radio>

                        <v-radio 
                        label="Outros" 
                        value=3 
                        color="#086318"
                        ></v-radio>
                      </v-radio-group>

                    </v-col>

                    <v-col class="d-flex" cols="12" sm="4" v-if="vue.identified == 'true'">
                      <v-radio-group 
                      :rules="formRules" 
                      v-model="vue.minor">

                        <p>Menor de Idade?</p>

                        <v-radio 
                        label="Sim" 
                        value="true" 
                        color="#086318"
                        ></v-radio>

                        <v-radio 
                        label="Não" 
                        value="false" 
                        color="#086318"
                        ></v-radio>

                      </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="d-flex" cols="12" sm="3" v-if="vue.hasCpf == 'true'">
                      <v-text-field 
                      color="#086318" 
                      :rules="formRules" 
                      label="CPF" 
                      placeholder="CPF" 
                      outlined
                      v-model="donorForm.cpf" 
                      v-mask="'###.###.###-##'" 
                      maxlength="14"
                      dense
                      ></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="5">
                      <v-text-field 
                      color="#086318" 
                      :rules="formRules" 
                      label="Nome" 
                      placeholder="Nome completo" 
                      outlined
                      v-model="donorForm.name" 
                      dense></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="3" v-if="vue.identified == 'true'">
                      <v-text-field 
                      color="#086318" 
                      :rules="formRules" 
                      label="Data de Nascimento"
          
                      type="date" 
                      outlined 
                      v-model="donorForm.birth_date"
                      dense></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="d-flex" cols="12" sm="4" v-if="vue.identified == 'true'">
                      <v-text-field 
                      color="#086318" 
                      label="Nome da Mãe" 
                      placeholder="Nome da mãe"
                      outlined 
                      v-model="donorForm.mother_name" 
                      dense
                      ></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="2" v-if="vue.identified == 'true'">
                      <v-text-field 
                      color="#086318" 
                      label="Telefone" 
                      placeholder="Telefone" 
                      outlined
                      v-mask="'(##)#####-####'" 
                      maxlength="14"
                      v-model="donorForm.telephone" 
                      dense
                      ></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="2" v-if="vue.identified == 'true'">
                      <v-text-field 
                      color="#086318" 
                      label="Cartão do SUS" 
                      placeholder="Cartão do SUS"
                      maxlength="15" 
                      v-mask="'#############'" 
                      outlined 
                      v-model="donorForm.sus_card" 
                      dense
                      ></v-text-field>
                    </v-col>

                    <v-col class="d-flex" cols="12" sm="3" v-if="vue.identified == 'true'">
                        <v-select 
                        color="#086318" 
                        v-model="donorForm.country_id" 
                        :items="countries"
                        item-text="country_name" 
                        item-value="id" 
                        dense 
                        outlined
                        label="Nacionalidade">
                        </v-select>
                      </v-col>
                  </v-row>

                  <div class="responsible" v-if="vue.minor == 'true'">
                    <v-row>
                      <h3 class="titles" row>Responsável</h3>
                    </v-row>
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="7">
                        <v-text-field 
                        color="#086318" 
                        label="Nome do Responsável"
                        placeholder="Nome completo" 
                        outlined 
                        v-model="donorForm.responsible"
                        dense>
                      </v-text-field>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="5">
                        <v-select 
                          color="#086318"  
                          :items="kinships"
                          v-model="donorForm.kinship_id" 
                          item-text="name"
                          item-value="id" 
                          dense
                          outlined
                          label="Grau de Parentesco">
                        </v-select>
                      </v-col>
                    </v-row>
                  </div>

                  <div class="address" v-if="vue.identified == 'true'">
                    <v-row>
                      <h3 class="titles" 
                      row>Endereço</h3>
                    </v-row>

                    <v-row>     
                      <v-col class="d-flex" cols="12" sm="2">
                        <v-text-field 
                        color="#086318" 
                        label="CEP" 
                        v-mask="'########'"
                        @focusout="getCep"
                        placeholder=""
                        outlined
                        v-model="donorForm.zip_code" 
                        dense>
                        </v-text-field>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="3">
                        <v-select 
                        color="#086318" 
                        placeholder="uf" 
                        :disabled="disableCep"
                        v-model="donorForm.state_id"
                        :items="state"
                        item-text="name" 
                        item-value="id" 
                        dense 
                        outlined 
                        label="Estado">
                        </v-select>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="3">
                        <v-text-field 
                        color="#086318" 
                        items="county" 
                        :disabled="disableCep"
                        v-model="donorForm.county"
                        item-text="name" 
                        item-value="id" 
                        dense 
                        outlined 
                        label="Cidade">
                        </v-text-field>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="3">
                        <v-text-field 
                        color="#086318" 
                        :disabled="disableCep"
                        label="Bairro" 
                        placeholder="Bairro" 
                        outlined
                        v-model="donorForm.district" 
                        dense
                        ></v-text-field>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="1">
                        <v-text-field 
                        color="#086318" 
                        label="N°" 
                        placeholder="" 
                        outlined
                        type="number"
                        v-model="donorForm.number"
                         dense></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="d-flex" cols="12" sm="6">
                        <v-text-field 
                        color="#086318"
                        :disabled="disableCep" 
                        label="Endereço"
                        placeholder="Ex: Rua X, Loteamento Y..." 
                        outlined 
                        v-model="donorForm.address"
                        dense
                        ></v-text-field>
                      </v-col>

                      <v-col class="d-flex" cols="12" sm="6">
                        <v-text-field 
                        color="#086318" 
                        label="Complemento"
                        placeholder="Ex: Próximo ao Mercadinho..." 
                        outlined 
                        v-model="donorForm.complement"
                        dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>

                  <div class="death" v-if="donorForm.donor_status_id == '2' && donorForm.kind_of_donor_id == '1'">
                    <v-row>
                      <h3 class="titles" 
                      row>Informações do Óbito</h3>
                    </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="4">
                      <v-radio-group v-model="donorForm.cooledBody">
                          <p>Corpo Resfriado?</p>

                          <v-radio label="Sim" 
                          value="true" 
                          color="#086318"
                          ></v-radio>

                          <v-radio label="Não" 
                          value="false" 
                          color="#086318"
                          ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                   <v-row>
                            <v-col class="d-flex" cols="12" sm="2">
                                <v-text-field
                                color="#086318" 
                                label="Data do Óbito" 
                                type="date" 
                                outlined
                                v-model="donorForm.deathDate" 
                                dense></v-text-field>
                            </v-col>

                            <v-col class="d-flex" cols="12" sm="2">
                                <v-text-field
                                color="#086318" 
                                label="Hora do Óbito" 
                                placeholder="Ex: 22:38" 
                                outlined
                                maxlength="5" 
                                v-mask="'##:##'"
                                v-model="donorForm.deathHour" 
                                dense></v-text-field>
                            </v-col>

                            <v-col class="d-flex" cols="12" sm="6">
                                <v-text-field 
                                color="#086318" 
                                label="Causa da Morte" 
                                placeholder="Infarto, falência renal..."
                                outlined
                                dense
                                v-model="donorForm.reason_of_death" 
                                ></v-text-field>
                            </v-col>

                            <v-col class="d-flex" cols="12" sm="2">
                                <v-select 
                                :items="cancer"
                                color="#086318" 
                                v-model="donorForm.cancer"
                                item-value="id"
                                item-text="name"  
                                dense 
                                outlined 
                                label="Câncer?">
                                </v-select>
                            </v-col>
                        </v-row>
                    <v-row>
                      <v-col class="d-flex" cols="12" sm="12">
                          <v-textarea 
                          color="#086318" 
                          label="História/Evolução Clínica (incluir Doenças Auto-Imunes, Neoplasias, Aneurismas, AVCs, IAMs, etc.)"
                          row-height="10"
                          rows="3"
                          :rules="formRules"
                          outlined
                          dense
                          v-model="donorForm.clinicalEvolution">
                          </v-textarea>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="buttons">
                    <v-row>
                      <v-btn class="rounded-lg" type="submit" elevation="0" color="green">
                        <span class="textBtn"> Salvar e continuar </span>
                      </v-btn>
                      <div class="spacediferent"></div>
                      <v-btn class="rounded-lg" elevation="0" @click="clear()" color="blue">
                        <span class="textBtn"> Limpar</span>
                      </v-btn>
                      <div class="spacediferent"></div>
                      <v-btn class="rounded-lg" elevation="0" @click="$router.push('/doador')" color="grey">
                        <span class="textBtn"> Voltar</span>
                      </v-btn>
                    </v-row>
                  </div>
                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <!-- TAB 2 -->
        <v-tab-item>
          <InformesClinicos @changeTab="tab = $event , this.disabledAttachments = false" />
        </v-tab-item>

        <!-- TAB 3 -->
        <!-- <v-tab-item>
          <Sorologia @changeTab="tab = $event" />
        </v-tab-item> -->

        <!-- TAB 4 -->
        <v-tab-item>
          <Anexos />
        </v-tab-item>


      </v-tabs>
    </v-card>

    <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
  </v-snackbar>
  </div>
</template>  
<script>

import axios from "@/axios";
import InformesClinicos from "./informesClínicos/InformesClinicos.vue";
//import Sorologia from "./sorologia/Sorologia-comp.vue";
import Anexos from "./anexos/Anexos-comp.vue";
// import { isArgumentsObject } from "util/types";

export default {
    data() {
        return {
            page: 1,
            tab: null,
            isME: false,
            totalUsers: 0,
            numberOfPages: 0,
            passengers: [],
            loading: true,
            options: {},
            sortBy: "name",
            sex_id: "",
            identified: "",
            orgon: [],
            disabled: true,
            message: "",
            filterUnity:"",
            snackbar: false,
            loadingBTN: false,
            hospitalSelected: "",
            exibirMensagem: false,
            messageError: "",
            donorForm: {
                is_donor: "sim",
                unity_id: "",
                hospital_id:""
            },
            activeDonor:[],
            donorOrgons: {},
            donor_hospital_id:'',
            kinships: [],
            hospital: [],
            countries: [],
            state: [],
            disableCep: false,
            newDonor: false,
            disabledReports: true,
            disabledAttachments: true,
            disableDonorStatus: false,
            id: "",
            vue: {
                identified: null,
                hasCpf: null,
                minor: null,
            },
            cancer: [
                    {
                        name: 'Qtx',
                        value: 'Qtx'
                    },
                    {
                        name: 'Rtx',
                        value: 'Rtx'
                    }
                ],
            donorActiveId: "",
            formRules: [
                (v) => !!v || "Este campo é obrigatório",
            ],
        };
    },

    components: {
      InformesClinicos,
      //Sorologia,
      Anexos
    },
    
    computed: {
        hospital_id() {
            return this.donorForm.hospital_id;
        },

        kind_of_donor_id() {
            return this.donorForm.kind_of_donor_id;
        },
    },

    created() {
        this.filterUnity = this.$store.state.unity_id

        axios.get("/unities").then((response) => {
          if(this.$store.state.profile_id == 1){
            this.hospital = response.data.data;
            
          }else{
            this.hospital = response.data.data;
            this.hospital = this.hospital.filter((x) => x.id == this.filterUnity);
          }
        });
        axios.get("/auth/me").then((response) => {
          this.donor_hospital_id = response.data.unity_id
        });
        axios.get("/kinship").then((response) => {
            this.kinships = response.data.data;
        });
        axios.get("/state").then((response) => {
            this.state = response.data.data;
        });
        axios.get("/countries").then((response) => {
            this.countries = response.data.data;
        });

        if(this.$route.query.item){

          this.donorForm =  { ...this.$route.query.item } 
          let cnes = this.donorForm.unities.cnes
          this.donorForm.cnes = cnes
          
          if(this.donorForm.is_donor == 'sim'){
            this.isME = true
          }
          this.vue.identified = "true"
          this.donorForm.kind_of_donor_id = "2"  
          this.donorForm.donor_status_id = "2"
          this.vue.hasCpf = "true" //validar se existe CPF ao vir de busca ativa
          this.donorForm.sex_id = this.$route.query.item.sex_id.toString();
          this.countAge()
          this.donorForm.hospital_id = parseInt(this.$route.query.item.unity_id);
        }
    },

    watch: {
        hospital_id(e) {
            var hospitalSelected = this.hospital.filter((x) => x.id == e)[0];
            this.donorForm.cnes = hospitalSelected.CNES;
        },

        kind_of_donor_id(e) {
           if(e == 2){
              this.donorForm.donor_status_id = '2'
              this.disableDonorStatus = true
           }else{
              this.disableDonorStatus = false
           }
        },

        sortBy: {
            handler() {
            }
        },
        options: {
            handler() {
                this.indoDataTable();
            },
        },
        deep: true,
    },

    methods: {
        indoDataTable() {
            this.loading = true;
        },

        openCreateDoador() {
            this.dialog = true;
        },

        clear() {
            this.donorForm = "";
            this.hasCpf = "";
            this.minor = "";
            this.identified = "";
        },

        getCep(){
          var cep = this.donorForm.zip_code
          axios.get(`/donor/cep/${cep}`).then((response) => {
            if(response.data.erro == true){
              alert("CEP não encontrado.")
              this.donorForm.zip_code = cep
              this.donorForm.district = ""
              this.donorForm.county = ""
              this.donorForm.address = ""
            }else{
              var uf = response.data.uf
                axios.get("/state").then((response) => {
                    this.state = response.data.data;
                    this.state = this.state.filter((x) => x.uf == uf)[0];
                    this.donorForm.state_id = this.state.id
                });
                this.donorForm.zip_code = response.data.cep
                this.donorForm.district = response.data.bairro
                this.donorForm.county = response.data.localidade
                this.donorForm.address = response.data.logradouro
            }
          }).catch((err) => {
            err;
            var error = "";
            alert("Preencha um CEP válido", error)
          });
        },

        countAge(){
          const date = new Date();
            const year = date.getFullYear();
              const bornYear = this.donorForm.birth_date.split("-")[0];
                const age = year-bornYear
        
          age >= 18 ? this.vue.minor = "false" : this.vue.minor = "true"
        },

        createDoador() {
            if(this.$refs.form.validate() && !this.$route.query.item) {
              if(this.$store.state.profile_id != 1){
                  this.donorForm.hospital_id = this.donor_hospital_id
                  this.donorForm.unity_id = this.donorForm.hospital_id;
              }else{
                this.donorForm.unity_id = this.donorForm.hospital_id;
              }
              this.donorForm.kind_of_donor_id = 3
                const doador = (this.donorForm);
                axios
                    .post(`/donor/salvarDoador`, doador)
                    .then((response) => {
                    this.indoDataTable();
                    this.message = response.data.message;
                    this.snackbar = true;
                    this.dialog = false;
                    const id = response.data.id.id
                    localStorage.setItem("donorId", id);
                    this.disabledReports = false
                    this.tab = 1
                })
                    .catch((err) => {
                    err;
                    var error = "";
                    Object.values(this.$store.state.errorMessage).map((value) => {
                        error = value;
                    });
                    this.displayError.message = error.toString();
                    this.displayError.display = true;
                    setTimeout(() => {
                        this.displayError.display = false;
                    }, 3000);
                });
            }else{
              const doador = this.donorForm;
                axios
                    .patch(`/donor/${doador.id}`, doador)
                    .then((response) => {
                    this.indoDataTable();
                    this.message = response.data.message;
                    this.snackbar = true;
                    this.dialog = false;
                    const id = doador.id
                    localStorage.setItem("donorId", id);
                    this.disabledReports = false
                    this.tab = 1
                })
                    .catch((err) => {
                    err;
                    var error = "";
                    Object.values(this.$store.state.errorMessage).map((value) => {
                        error = value;
                    });
                    this.displayError.message = error.toString();
                    this.displayError.display = true;
                    setTimeout(() => {
                        this.displayError.display = false;
                    }, 3000);
                });
            }
        },
        
        mounted() {
            this.indoDataTable();
        },
    }
};
</script>

<style scoped>
.header-table {
  display: flex;
  margin-bottom: 20px;
}

.space {
  width: 15px;
}

.spacediferent {
  width: 7px;
}

.space-personalized {
  padding: 15px;
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 40px !important;
}

.textBtn {
  font-family: "Poppins";
  color: #ffffff;
}

.create-user-dialog {
  padding: 20px;
}

.card {
  padding: 35px;
}

.banner {
  background: #b3ffab;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right,
      #27ae60,
      #1abc9c);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,
      #ee106d,
      #e46987);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  border-radius: 8px;

  margin-bottom: 20px;

  display: flex;
  align-items: center;
}

.banner>h4 {
  color: #ffffff;
  padding: 10px;
}

.cardFooter {
  padding: 0 !important;
}
</style>
