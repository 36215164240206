<template>
    <v-row justify="center">
      <v-dialog
        v-model="activeModal"
        persistent
        width="450"
      >
        <v-card>
          <v-card-title>
            <v-icon style="padding: 5px; color: rgb(210, 22, 22);">mdi-comment-question</v-icon>
            <span class="text-h5">Motivo da Recusa</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                    <v-textarea
                        label="Descreva aqui:"
                        auto-grow
                        variant="outlined"
                        :rules="formRules" 
                        rows="3"
                        color="#d21631"
                        row-height="25"
                        v-model="refused"
                        shaped
                    ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <small>*Ao confirmar recusa, este doador não avançará para a próxima etapa de captação. Esta ação não poderá ser revertida.</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue-darken-1"
              variant="text"
              @click="closeDialog()"
            >
              cancelar
            </v-btn>
            <v-btn
              style="background-color:#d21631; color: white;"
              variant="text"
              @click="sendRefusal()"
            >
              enviar recusa
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

        <v-snackbar background-color="white" color="#57b567" v-model="snackbar">
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
                Fechar
                </v-btn>
            </template>
        </v-snackbar>

        <v-snackbar background-color="white" color="#d21631" v-model="snackbarError">
            {{ messageError }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="snackbarError = false">
                Fechar
                </v-btn>
            </template>
        </v-snackbar>
    </v-row>
  </template>

  <script>
  import axios from "@/axios";
  export default {
    data: () => ({
      dialog: false,
      refused: "",
      snackbar: false,
      message:"",
      formRules: [
          (v) => !!v || "Este campo é obrigatório",
      ],
      messageError:"",
      snackbarError:false,
      dialogError:""
    }),

    props:{
        activeModal: Boolean,
        donorId: Object
    },

    methods:{
    
        closeDialog(){
            this.$emit('fechar');
        },

        sendRefusal(){
          if(this.refused) {
            const data =  new Object();
            data.justification = this.refused
            data.donor_id = this.donorId.donor_id
            data.authorization_status_id = 2
            
            console.log("Data", data)
            axios
                .post(`/family_authorization/create`, data)
                .then(() => {
                this.message = "Recusa submetida!";
                this.snackbar = true;
                this.dialog = false;
                this.$router.push({ name: "Doadores" });
            })
                .catch((err) => {
                err;
                var error = "";
                Object.values(this.$store.state.errorMessage).map((value) => {
                    error = value;
                });
                this.displayError.message = error.toString();
                this.displayError.display = true;
                setTimeout(() => {
                    this.displayError.display = false;
                }, 3000);
            });
          }else{
            this.messageError = "Verifique se o campo está preenchido corretamente.";
            this.snackbarError = true;
            this.dialogError = false;
          }
        }
    }
  }
</script>

<style>

small{
    font-size: 14px;
}
</style>

