<template>
  <div>
      <v-card flat>
      <v-card-text>
      <!-- alterei o ref -->
      <v-form @submit.prevent="createDoadorReports" ref="form">
          <v-container>
          <v-row>
              <v-col class="d-flex" cols="12" sm="3">
                  <v-text-field 
                  color="#086318" 
                  :rules="formRules" 
                  label="Data de Admissão"
                  type="date" 
                  outlined 
                  v-model="reportsForm.admission_date"
                  dense></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="3">
                  <v-text-field 
                  color="#086318" 
                  label="Altura"
                  maxlength="4" 
                  v-mask="'#.##'"
                  outlined
                  dense
                  v-model="reportsForm.height">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="3">
                  <v-text-field 
                  color="#086318" 
                  label="Peso"
                  outlined
                  dense
                  v-model="reportsForm.weight">
                  </v-text-field>
              </v-col>
              
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12" sm="3">
                  <v-select 
                  color="#086318" 
                  v-model="reportsForm.diagnostic_id" 
                  :items="diagnostics"
                  item-text="name" 
                  item-value="id" 
                  dense 
                  outlined
                  label="Suspeita de Diagnóstico">
                  </v-select>
              </v-col>
              <v-col class="d-flex" cols="12" sm="3">
                  <v-select 
                  color="#086318" 
                  v-model="reportsForm.comorbidity_id" 
                  :items="comorbidities"
                  item-text="name" 
                  item-value="id" 
                  dense 
                  outlined
                  label="Comorbidades">
                  </v-select>
              </v-col>
              <v-col class="d-flex" cols="12" sm="3" v-if="this.reportsForm.comorbidity_id == 5">
                  <v-text-field 
                  color="#086318" 
                  label="Causa Provável da Morte"
                  outlined
                  dense
                  v-model="reportsForm.probable_cause">
                  </v-text-field>
              </v-col>
          </v-row>
  
          <v-row>
              <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.donor_diabetes">
                      <p>Diabetes no doador?</p>

                      <v-radio label="Sim" 
                      value="1" 
                      color="#086318"
                      ></v-radio>

                      <v-radio label="Não" 
                      value="2" 
                      color="#086318"
                      ></v-radio>
                  </v-radio-group>
              </v-col>

              <v-col class="d-flex" cols="12" sm="3">
                  <v-radio-group :rules="formRules" v-model="reportsForm.diabetes_relatives">

                  <p>Diabetes em parentes de 1ª grau?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>

              <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.bronchoaspiration">
                  <p>Broncoaspiração no Internamento?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>

              <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.inpatient_dialysis">

                  <p>Diálise no Internamento?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>

              <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.dst">
                  <p>DST?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>

              <v-col class="d-flex" cols="12" sm="1">
                  <v-radio-group :rules="formRules" v-model="reportsForm.has">
                  <p>HAS?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
          </v-row>

          <v-row>
            <v-col class="d-flex" cols="12" sm="2">
                <v-radio-group :rules="formRules" v-model="reportsForm.asthma">
                <p>Asma?</p>
                    <v-radio 
                    label="Sim"  
                    value=1
                    color="#086318"></v-radio>

                    <v-radio label="Não"  
                    value=2
                    color="#086318"></v-radio>
                </v-radio-group>
            </v-col>
            <v-col class="d-flex" cols="12" sm="2">
                <v-radio-group :rules="formRules" v-model="reportsForm.dpoc">
                <p>DPOC?</p>
                    <v-radio 
                    label="Sim"  
                    value=1
                    color="#086318"></v-radio>

                    <v-radio label="Não"  
                    value=2
                    color="#086318"></v-radio>
                </v-radio-group>
            </v-col>
            <v-col class="d-flex" cols="12" sm="2">
                <v-radio-group :rules="formRules" v-model="reportsForm.facial_trauma">
                <p>Trauma na face?</p>
                    <v-radio 
                    label="Sim"  
                    value=1
                    color="#086318"></v-radio>

                    <v-radio label="Não"  
                    value=2
                    color="#086318"></v-radio>
                </v-radio-group>
            </v-col>
            <v-col class="d-flex" cols="12" sm="2">
                <v-radio-group :rules="formRules" v-model="reportsForm.piercing">
                <p>Piercings?</p>
                    <v-radio 
                    label="Sim"  
                    value=1
                    color="#086318"></v-radio>

                    <v-radio label="Não"  
                    value=2
                    color="#086318"></v-radio>
                </v-radio-group>
            </v-col>
            <v-col class="d-flex" cols="12" sm="3">
                <v-radio-group :rules="formRules" v-model="reportsForm.corneal_surgery">
                <p>Relato de cirurgia na córnea?</p>
                    <v-radio 
                    label="Sim"  
                    value=1
                    color="#086318"></v-radio>

                    <v-radio label="Não"  
                    value=2
                    color="#086318"></v-radio>
                </v-radio-group>
            </v-col>
          </v-row>


          <v-row>
              <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.smoker">
                  <p>Tabagista?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.smoker == 1">
                  <v-text-field 
                  color="#086318" 
                  label="Há quanto tempo?" 
                  dense
                  v-model="reportsForm.how_long" 
                  ></v-text-field>
              </v-col>

              <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.previous_surgery">
                  <p>Cirúrgia Prévia?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.previous_surgery == 1">
                  <v-text-field 
                  color="#086318" 
                  label="Qual?" 
                  dense
                  v-model="reportsForm.which_surgery" 
                  ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.heart_disease">

                  <p>Cardiopatia?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.heart_disease == 1">
                  <v-text-field 
                  color="#086318" 
                  label="Qual?" 
                  dense
                  v-model="reportsForm.which_heart_disease" 
                  ></v-text-field>
              </v-col>
          </v-row>


          <v-row>
              <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.infectious_disease">
                  <p>Doença Infectocontagiosa?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.infectious_disease == 1">
                  <v-text-field 
                  color="#086318" 
                  label="Qual?" 
                  dense
                  v-model="reportsForm.which_infectious" 
                  ></v-text-field>
              </v-col>

              <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.tattoo">
                  <p>Tatuagens?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.tattoo == 1">
                  <v-text-field 
                  color="#086318" 
                  label="Tempo:" 
                  dense
                  v-model="reportsForm.tattoo_time" 
                  ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.illicit_drugs">
                  <p>Usuários de drogas ilícitas?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="injectable_drugs" v-if="reportsForm.illicit_drugs == 1">
                  <p>Injetáveis?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="3" v-if="injectable_drugs == 1 && reportsForm.illicit_drugs == 1">
                <v-textarea 
                  color="#086318" 
                  label="Quais?"
                  row-height="12"
                  rows="4"
                  outlined
                  dense
                  v-model="reportsForm.injecting_drugs">
                  </v-textarea>
              </v-col>

              <v-col class="d-flex" cols="12" sm="3" v-if="injectable_drugs == 2 && reportsForm.illicit_drugs == 1">
                <v-textarea 
                  color="#086318" 
                  label="Quais não injetáveis?"
                  row-height="12"
                  rows="4"
                  outlined
                  dense
                  v-model="reportsForm.non_injecting_drugs">
                  </v-textarea>
              </v-col>
          </v-row>
   
          <v-row>
              <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group :rules="formRules" v-model="reportsForm.alcoholic">
                  <p>Alcoolista?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.alcoholic == 1">
                  <v-text-field 
                  color="#086318" 
                  label="Tipo:" 
                  dense
                  v-model="reportsForm.type_of_alcoholic" 
                  ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.alcoholic == 1">
                  <v-text-field 
                  color="#086318" 
                  label="Tempo:" 
                  dense
                  v-model="reportsForm.time_of_alcoholic" 
                  ></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.alcoholic == 1">
                  <v-text-field 
                  color="#086318" 
                  label="Frequência:" 
                  dense
                  v-model="reportsForm.frequency_of_alcoholic" 
                  ></v-text-field>
              </v-col>
              <!-- <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.alcoholic == 1">
                <v-tooltip top color="green">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mx-1" small fab dark color="green" v-bind="attrs" v-on="on" @click="addAntibiotic()">
                      <v-icon dark>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </template>
                  <span style="color:white">Visualizar</span>
                </v-tooltip>
              </v-col> -->
          </v-row>
          <v-row>
              <v-col class="d-flex" cols="12" sm="5">
                <v-textarea 
                  color="#086318" 
                  label="Outros..."
                  row-height="15"
                  rows="8"
                  outlined
                  dense
                  v-model="reportsForm.others">
                  </v-textarea>
              </v-col>
          </v-row>
          <br>
      <div class="antecedentes" v-if="antecedentes == true">
          <h3>Antecedentes:</h3>
          <v-row>
            <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group v-model="reportsForm.blood_products">
                  <p>Recebeu sangue e Hemoderivados?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.blood_products == 1">
                <v-text-field 
                  color="#086318" 
                  label="Tipo:"
                  outlined
                  dense
                  v-model="bloodProductsForm.type_of_blood_products">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.blood_products == 1">
                <v-text-field 
                  color="#086318" 
                  label="Volume:"
                  outlined
                  dense
                  v-model="bloodProductsForm.volume_of_blood_products">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.blood_products == 1">
                <v-text-field 
                  color="#086318" 
                  label="Data:"
                  type="date" 
                  outlined 
                  v-model="bloodProductsForm.data_of_blood_products"
                  dense></v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.blood_products == 1">
                <v-tooltip top color="green">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mx-1" small fab dark color="green" v-bind="attrs" v-on="on" @click="addHemoderivates()">
                      <v-icon dark>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </template>
                  <span style="color:white">Adicionar</span>
                </v-tooltip>
                  <v-tooltip top color="blue">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-1" small fab dark color="blue" v-bind="attrs" v-on="on" @click="openHemoderivadosTable()">
                            <v-icon dark>mdi-eye</v-icon>
                        </v-btn>
                    </template>
                    <span style="color:white">Visualizar</span>
                </v-tooltip>
              </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group  v-model="reportsForm.positive_blood_culture">
                  <p>Hemocultura Positiva?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="3" v-if="reportsForm.positive_blood_culture == 1">
                <v-text-field 
                  color="#086318" 
                  label="Germe Isolado:"
                  outlined
                  dense
                  v-model="positiveBloodForm.isolated_germ">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="3" v-if="reportsForm.positive_blood_culture == 1">
                <v-tooltip top color="green">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-1" small fab dark color="green" v-bind="attrs" v-on="on" @click="addPositiveCulture()">
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span style="color:white">Adicionar</span>
                </v-tooltip>
                <v-tooltip top color="blue">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-1" small fab dark color="blue" v-bind="attrs" v-on="on" @click="openhemoculturaTable()">
                            <v-icon dark>mdi-eye</v-icon>
                        </v-btn>
                    </template>
                    <span style="color:white">Visualizar</span>
                </v-tooltip>
              </v-col>

          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group v-model="reportsForm.infection">
                  <p>Infecção?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.infection == 1">
                <v-text-field 
                  color="#086318" 
                  label="Local:"
                  outlined
                  dense
                  v-model="infectionForm.site_of_infection">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.infection == 1">
                <v-text-field 
                  color="#086318" 
                  label="Antibiótico:"
                  outlined
                  dense
                  v-model="infectionForm.infection_antibiotic">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.infection == 1">
                <v-text-field 
                  color="#086318" 
                  label="Dose:"
                  outlined
                  dense
                  v-model="infectionForm.dose_of_antibiotic">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.infection == 1">
                <v-text-field 
                  color="#086318" 
                  label="Início:"
                  type="date" 
                  outlined
                  dense
                  v-model="infectionForm.antibiotic_start_date">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="2" v-if="reportsForm.infection == 1">
                  <v-tooltip top color="green">
                    <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mx-1" small fab dark color="green" v-bind="attrs" v-on="on" @click="addAntibiotic()">
                    <v-icon dark>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                    <span style="color:white">Adicionar</span>
                </v-tooltip>
                  <v-tooltip top color="blue">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-1" small fab dark color="blue" v-bind="attrs" v-on="on" @click="openInfectionsTable()">
                            <v-icon dark>mdi-eye</v-icon>
                        </v-btn>
                    </template>
                    <span style="color:white">Visualizar</span>
                </v-tooltip>
                  <p class="space"></p>
              </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12" sm="2">
                  <v-radio-group v-model="reportsForm.material_for_serology">
                  <p>Coleta de material para sorologia?</p>
                      <v-radio 
                      label="Sim"  
                      value=1
                      color="#086318"></v-radio>

                      <v-radio label="Não"  
                      value=2
                      color="#086318"></v-radio>
                  </v-radio-group>
              </v-col>
              <v-col class="d-flex" cols="12" sm="4" v-if="reportsForm.material_for_serology == 1">
                <v-text-field 
                  color="#086318" 
                  label="Local da coleta:"
                  outlined
                  dense
                  v-model="materialForSerologyForm.collection_location">
                  </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="1" v-if="reportsForm.material_for_serology == 1">
                <v-tooltip top color="green">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-1" small fab dark color="green" v-bind="attrs" v-on="on" @click="addMaterialForSerology()">
                            <v-icon dark>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span style="color:white">Adicionar</span>
                </v-tooltip>
                <v-tooltip top color="blue">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mx-1" small fab dark color="blue" v-bind="attrs" v-on="on" @click="openSorologyTable()">
                            <v-icon dark>mdi-eye</v-icon>
                        </v-btn>
                    </template>
                    <span style="color:white">Visualizar</span>
                </v-tooltip>
            </v-col>
          </v-row>
          <h3>(Não enuclear antes da coleta! Centrifugar e armazenar sob refrigeração.)</h3>
      </div>
          <template>
            <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="5"
                class="elevation-1">
                <template v-slot:item.actions="{ item }">
                    <v-icon 
                    size="20"
                    class="icon"
                    color="red" 
                    align-item="center"
                    @click="deleteAttachment(item)">
                    mdi-trash-can-outline
                    </v-icon>
                    <v-icon 
                    size="20"
                    class="icon"
                    color="red" 
                    align-item="center"
                    @click="deleteAttachment(item)">
                    mdi-trash-can-outline
                    </v-icon>
                </template>
            </v-data-table>
        </template>
        <br>
          <div class="buttons">
              <v-row>
              <v-btn class="rounded-lg" @click="salvarInformes()" elevation="0" color="green" v-if="buttonSave == true">
                  <span class="textBtn" style="color:white"> Salvar e continuar </span>
              </v-btn>
              <div class="spacediferent"></div>
              <v-btn class="rounded-lg" @click="$emit('changeTab', 2)" elevation="0" color="green" v-if="this.informesSalvos == true">
                  <span class="textBtn"  style="color:white"> Continuar </span>
              </v-btn>
              <div class="spacediferent"></div>
              <v-btn class="rounded-lg" elevation="0" @click="clear()" color="blue">
                  <span class="textBtn"  style="color:white" > Limpar</span>
              </v-btn>
              </v-row>
          </div>
          </v-container>
      </v-form>
      </v-card-text>
      
  </v-card>
  <v-snackbar absolute background-color="white" color="#104a21" v-model="snackbar">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Fechar
        </v-btn>
      </template>
  </v-snackbar>
</div>
</template>
<script>
import axios from "@/axios";
// import { isArgumentsObject } from "util/types";

export default {
data() {
  return {
    headers:[],
    hemoderivadosHeaders: [
      {
        text: "Tipo",
        align: "center",
        value: "type_of_blood_products",
      },

      { 
          text: "Volume", 
          value: "volume_of_blood_products", 
          align: "center",
          sortable: false 
      },

      { 
          text: "Data", 
          value: "data_of_blood_products", 
          align: "center",
          sortable: false 
      },
    ],
    hemoculturaHeaders: [
      {
        text: "Germe Isolado",
        align: "center",
        value: "isolated_germ",
      },
    ],
    InfeccoesHeaders: [
      {
        text: "Local",
        align: "center",
        value: "site_of_infection",
      },

      {
        text: "Antibiótico",
        align: "center",
        value: "infection_antibiotic",
      },

      {
        text: "Dose",
        align: "center",
        value: "dose_of_antibiotic",
      },

      {
        text: "Início",
        align: "center",
        value: "antibiotic_start_date",
      },
    ],
    sorologiaHeaders: [
      {
        text: "Local de Coleta",
        align: "center",
        value: "collection_location",
      },
    ],
    page: 1,
    tab: 1,
    informesSalvos: false,
    totalUsers: 0,
    numberOfPages: 0,
    passengers: [],
    loading: true,
    options: {},
    sortBy: "name",
    message: "",
    snackbar: false,
    loadingBTN: false,
    hospitalSelected: "",
    exibirMensagem: false,
    messageError: "",
    reportsForm: {
      donor_id: ""
    },
    infectionForm:{
      donor_id: ""
    },
    bloodProductsForm:{
      clinical_reports_id:""
    },
    positiveBloodForm:{
      clinical_reports_id:""
    },
    materialForSerologyForm:{
      clinical_reports_id:""
    },
    clinical_reports_id: "",
    diagnostics: [],
    comorbidities: [],
    injectable_drugs:"",
    antecedentes: false,
    buttonSave: true,
    vue: {
      hasDST: null,
      isSmoker: null,
      hasSugery: null,
      hasInfectiousDiesease: null
    },
    donorID: "",
    items: [],
    hemoderivados: [],
    hemocultura: [],
    infections: [],
    material_for_serology: [],
    formRules: [
      (v) => !!v || "Este campo é obrigatório",
    ],
  };
},

created() {

  this.donorID = localStorage.getItem('donorId')
  
  axios.get("/diagnostics").then((response) => {
    this.diagnostics = response.data.data;
  });

  axios.get("/comorbidities").then((response) => {
    this.comorbidities = response.data.data;
  });

  axios.get("/state").then((response) => {
    this.state = response.data.data;
  });
  
  axios.get("/countries").then((response) => {
    this.countries = response.data.data;
  });
},

watch: {

  sortBy: {
    handler() {

    }
  },

  options: {
    handler() {
      this.indoDataTable();
    },
  },

  deep: true,
},

methods: {

  indoDataTable() {
    this.loading = true;
  },

  clear() {
    this.reportsForm = ""
  },

  salvarInformes(){
    if(this.$refs.form.validate()) {
      this.reportsForm.donor_id = this.donorID
      const donorReport = this.reportsForm
      axios
        .post(`/clinical_reports/create`, donorReport).then((response) => {
          this.indoDataTable();   
          this.message = response.data.message;
          this.clinical_reports_id = response.data.id.id
          this.bloodProductsForm.clinical_reports_id = this.clinical_reports_id //remover isso depois
          this.positiveBloodForm.clinical_reports_id = this.clinical_reports_id
          this.materialForSerologyForm.clinical_reports_id = this.clinical_reports_id
          this.snackbar = true;
          this.dialog = false; 
          this.antecedentes = true;
          this.buttonSave = false;
          this.informesSalvos = true;
          // this.$emit('changeTab', 2);
          })
            .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
                error = value;
            });
            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
                this.displayError.display = false;
            }, 3000);
          });
    }
  },

  addHemoderivates(){
      const bloodProducts = this.bloodProductsForm
      axios
        .post(`/blood_products`, bloodProducts).then((response) => {
          this.message = response.message;
          this.snackbar = true;
          this.dialog = false;
          this.message = "Inclua mais hemoderivados clicando no +."
          this.bloodProductsForm.type_of_blood_products = "";
          this.bloodProductsForm.volume_of_blood_products = "";
          this.bloodProductsForm.data_of_blood_products = "";
        })
            .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
                error = value;
            });
            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
                this.displayError.display = false;
            }, 3000);
          });
  },

  addPositiveCulture(){
      const positiveCulture = this.positiveBloodForm
      axios
        .post(`/positive_blood_culture`, positiveCulture).then((response) => {
          this.message = response.message;
          this.snackbar = true;
          this.dialog = false;
          this.message = "Inclua mais germes isolados clicando no +."
          this.positiveBloodForm.isolated_germ = "";
        })
            .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
                error = value;
            });
            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
                this.displayError.display = false;
            }, 3000);
          });
  },

  addMaterialForSerology(){
      const materialForSerology = this.materialForSerologyForm
      axios
        .post(`/material_for_serology`, materialForSerology).then((response) => {
          this.message = response.message;
          this.snackbar = true;
          this.dialog = false;
          this.message = "Inclua mais locais de coleta clicando no +."
          this.materialForSerologyForm.collection_location = "";
        })
            .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
                error = value;
            });
            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
                this.displayError.display = false;
            }, 3000);
          });
  },

  addAntibiotic(){
    if(this.$refs.form.validate()) {
      this.infectionForm.donor_id = this.donorID
      const infectionData = this.infectionForm
      axios
        .post(`/infections`, infectionData).then((response) => {
          this.message = response.message;
          this.snackbar = true;
          this.dialog = false;
          this.message = "Inclua mais antibióticos clicando no +."
          this.infectionForm.antibiotic_start_date = "";
          this.infectionForm.site_of_infection = "";
          this.infectionForm.dose_of_antibiotic = "";
          this.infectionForm.infection_antibiotic = "";
          })
            .catch((err) => {
            err;
            var error = "";
            Object.values(this.$store.state.errorMessage).map((value) => {
                error = value;
            });
            this.displayError.message = error.toString();
            this.displayError.display = true;
            setTimeout(() => {
                this.displayError.display = false;
            }, 3000);
          });
    }
  },

  openHemoderivadosTable(){
    this.headers = this.hemoderivadosHeaders
      axios.get(`/blood_products/${this.clinical_reports_id}`).then((response) => {
        this.hemoderivados = response.data;
        this.items = this.hemoderivados
      });
  },

  openhemoculturaTable(){
    this.headers = this.hemoculturaHeaders
    axios.get(`/positive_blood_culture/${this.clinical_reports_id}`).then((response) => {
        this.hemocultura = response.data;
        this.items = this.hemocultura
    });
  },

  openInfectionsTable(){
    this.headers = this.InfeccoesHeaders
    axios.get(`/infections/byDonor/${this.donorID}`).then((response) => {
        this.infections = response.data;
        this.items = this.infections
    });
  },

  openSorologyTable(){
    this.headers = this.sorologiaHeaders
    axios.get(`/material_for_serology/${this.clinical_reports_id}`).then((response) => {
        this.material_for_serology = response.data;
        this.items = this.material_for_serology
    });
  },

  mounted() {
    this.indoDataTable();
  },
}

};
</script>

<style scoped>
.header-table {
display: flex;
margin-bottom: 20px;
}

.space {
width: 15px;
}

.spacediferent {
width: 7px;
}

.space-personalized {
padding: 15px;
}

.v-btn:not(.v-btn--round).v-size--default {
height: 40px !important;
}
material_for_serology
.textBtn {
font-family: "Poppins";
color: #ffffff;
}

.create-user-dialog {
padding: 20px;
}

.card {
padding: 35px;
}

.banner {
background: #b3ffab;
/* fallback for old browsers */
background: -webkit-linear-gradient(to right,
    #27ae60,
    #1abc9c);
/* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right,
    #ee106d,
    #e46987);
/* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

border-radius: 8px;

margin-bottom: 20px;

display: flex;
align-items: center;
}

.banner>h4 {
color: #ffffff;
padding: 10px;
}

.cardFooter {
padding: 0 !important;
}
</style>
